import React, { useState, useEffect, useRef } from 'react';
import minus from '../img/icons/basket-card-minus.svg';
import plus from '../img/icons/basket-card-plus.svg';
import ExpandButton from './ExpandButton';
import { useBreakPoint } from '../hooks/useBreakPoint';
import { useDispatch, useSelector } from 'react-redux';
import { getCals, getExtras, checkIfCustomisable, getCardButtons, checkIfPizza, getItemIndexByUniqueId } from '../helpers/basketHelpers';
import DropDown from './DropDown';
import DropDownField from './DropDownField';
import chevronDown from './../img/icons/chevronDown.svg';
import DietaryIcons from './DietaryIcons';
import { setBaseSelections } from '../store/menuSlice';
import { freshRelevance, gtmEvent } from '../helpers/commonHelpers';
import { config } from '../config';

const getBundleOptions = (bundle) => {
  const optionsArr = bundle.sections.reduce((acc, cur) => {
    const products = cur.products.map(p => ({
      name: p.product.subProducts?.[0]?.subProducts?.[0].name ? `${p.product.name} ${p.product.subProducts?.[0]?.subProducts?.[0].name}` : p.product.name,
      cost: p.product.upliftPrice ? p.product.upliftPrice : 0,
      quantity: p.product.quantity
    }));
    return acc.concat(products);
  }, []);
  return optionsArr;
};

const MenuItem = props => {
  const restaurant = useSelector(state => state.session.restaurant);
  const baseSelections = useSelector(state => state.menu.baseSelections);
  const basket = useSelector(state => state.basket);
  const { inApp } = useSelector(state => state.session.inApp);
  const activeCategory = useSelector(state => state.menu.activeCategory);
  const { customer } = useSelector(state => state.session);
  const { id, name, cost, calculatedCost, imageUrl, description, quantity, subProducts, customID, basePrice, uiTags, dietPreferences, outOfStock } = props.item;
  const { columnWidth, menuItem, bundleItem, numberInBasket, screenWidth, addItem, removeItem, navigateTo, isBundle, dietaryOptions, idx } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const isScreenSmall = useBreakPoint(screenWidth, 768);
  const textRef = useRef(null);
  const [showButton, setShowButton] = useState(false);
  const [extras, setExtras] = useState();
  const [selectedBase, setSelectedBase] = useState();
  const [selectedBaseCost, setSelectedBaseCost] = useState(cost || basePrice);
  const dispatch = useDispatch();
  const brand = config.BRAND;

  useEffect(() => {
    if (subProducts && subProducts[0].subProducts.length > 1) {
      const matchingBaseSelection = baseSelections.find(b => b.product === id);
      if (matchingBaseSelection) {
        setSelectedBase(matchingBaseSelection.base);
        setSelectedBaseCost(subProducts[0].subProducts.find(s => s.id === matchingBaseSelection.base).cost);
      } else {
        const defaultSelection = subProducts[0].defaultSubProduct;
        const lastSelection = subProducts[0].subProducts[subProducts[0].subProducts.length - 1].id;
        const selection = subProducts[0].subProducts.find(sp => sp.id === (defaultSelection ?? lastSelection));
        if (selection)
        {
          setSelectedBase(selection.id);
          setSelectedBaseCost(selection.cost);
        }
      }
    }
  }, [subProducts, baseSelections]);

  const expand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (customID && !isBundle) {
      const newExtras = getExtras(props.item);
      setExtras({ base: newExtras[0]?.name?.split('-')[0], toppings: newExtras.slice(1) });
    } else if (isBundle) {
      const newExtras = getBundleOptions(props.item);
      setExtras({ items: newExtras });
    } else {
      setExtras({ items: [] });
    }
  }, [props.item]);

  const updateBaseSelection = (v, e) => {
    setSelectedBase(e);
    setSelectedBaseCost(subProducts[0].subProducts.find(base => base.id === e).cost);

    if (!baseSelections.some(b => b.product === id)) {
      dispatch(setBaseSelections([...baseSelections, { product: id, base: e }]));
    } else {
      dispatch(setBaseSelections(baseSelections.map(base => base.product === id ? { product: id, base: e } : base)));
    }
  };

  const quickAdd = () => {
    if (props.item.subProducts[0].defaultSubProduct && selectedBase) {
      const selectedDefault = props.item.subProducts[0].subProducts.find(base => base.id === selectedBase);
      const quickAddItem = {
        ...props.item,
        displayPrice: selectedDefault.cost,
        subProducts: [{ ...props.item.subProducts[0], defaultSubProduct: selectedDefault.id }],
      };
      addItem(quickAddItem, true);
      freshRelevance('pageChange', null, { 'sendBeacon': true });
    } else {
      if (checkIfCustomisable(subProducts)) {
        freshRelevance('pageChange', null, { 'sendBeacon': true });
        addItem(props.item, true);
      } else {
        addItem(props.item);
      }
    }
  };

  const isOverflown = (ref) => {
    if (ref.current !== null) {
      return setShowButton(ref.current?.offsetHeight < ref.current?.scrollHeight || ref.current?.offsetWidth < ref.current?.scrollWidth);
    }
  };

  const handleCustomiseClick = () => {
    const event = {
      'currency': brand === 'PE' ? 'GBP' : 'EUR',
      clickAndCollectAction: basket.isDelivery ? 'Delivery_Product_Details_Clicked' : 'Collection_Product_Details_Clicked',
      channel: inApp ? 'App' : 'Web',
      value: basket.total,
    };
    if (customer?.customerSessionToken?.pizzaExpressId) {
      event.customerId = customer.customerSessionToken.pizzaExpressId;
    }
    const item = {
      'item_name' : props.item.name,
      'price' : props.item.cost ? props.item.cost : props.item.basePrice, // Bundle obj uses basePrice
      'item_brand' : brand,
      'item_category' : activeCategory,
      index: getItemIndexByUniqueId(basket.items, props.item),
    };
  
    if (props.item.id) {  // Bundle obj doesn't have id
      item['item_id'] = props.item.id;
    }
    var items = [];
    items.push(item);
    event['items'] = items;
    
    
    gtmEvent('product_details_event', event);
  };

  useEffect(() => {
    isOverflown(textRef);
  }, [textRef]);

  let displayCost = calculatedCost || selectedBaseCost;
  if (!displayCost) displayCost = 0;

  const cardButtons = getCardButtons(props, subProducts, quickAdd, navigateTo, restaurant?.id, id, handleCustomiseClick);
  const hidePiccoloCalories = cardButtons.length === 1 && cardButtons[0].text === 'Customise';
  const getCalories = () => {
    if (props.hideCalories || hidePiccoloCalories) return '';
    return <span className="calories">{getCals(props.item, selectedBase)}</span>;
  };

  let discountTotal = 0;
  const discounts = props?.discounts?.filter(d => d.id === id || d.id === customID) || [];
  discounts.forEach(discount => discountTotal += discount.amount);
  const displayCostAfterDiscount = displayCost - discountTotal;

  return (
    <div
      className={`basket-card-wrapper column is-${columnWidth} is-flex-direction-column is-align-items-stretch is-align-content-stretch`}
    >
      <div
        className={`basket-card is-flex-direction-column is-justify-content-space-between is-radius-very-large ${menuItem && !isScreenSmall ? '' : 'remove-border'} ${numberInBasket > 0 ? 'has-banner' : ''}`}
      >

        {numberInBasket > 0
          ? (
            <div
              className={'number-in-basket'}
            >
              {numberInBasket} added to basket
            </div>)
          : null
        }
        <div className={`basket-card-content-wrapper block is-flex-direction-row-mobile ${menuItem ? 'is-flex-direction-column-tablet' : 'is-flex-direction-row-tablet'}  p-0`} >
          <div
            className={`basket-card-image-wrapper column p-0 is-3-mobile ${menuItem ? 'is-12-tablet' : 'is-2-tablet'}`}
          >
            <figure style={{ maxWidth: '100%' }}>
              <img className="image" src={imageUrl} alt="food item" />
              <div
                className="ui-tags-wrapper desktop"
                style={{
                  position: 'absolute',
                  bottom: isScreenSmall ? '13px' : '16px',
                  left: isScreenSmall ? '-174px' : '16px',
                  height: '20px',
                  gap: '10px'
                }}
              >
                {uiTags && uiTags.map(tag => {
                  if (tag.toLowerCase() == 'medium image') {
                    return (<React.Fragment key={tag}></React.Fragment>);
                  } else if (tag.toLowerCase() == 'it\'s back!') {
                    return (<div
                      key={tag}
                      className={'ui-tag ui-tag-new'}
                    >
                      {tag}
                    </div>);
                  } else {
                    return (<div
                      key={tag}
                      className={`ui-tag ui-tag-${tag.toLowerCase()}`}
                    >
                      {tag} {/* Keep the word 'Favourites' but style as Popular */}
                    </div>);
                  }
                })}
              </div>
            </figure>
          </div>

          <p
            className="card-title is-text-weight-semibold"
            style={{ flex: '1', marginRight: '0.5rem' }}
          >
            {name}
          </p>
          {
            id !== '10000012787' &&
            <>
              {menuItem ? dietaryOptions && <DietaryIcons icons={dietaryOptions} style={{ alignItems: 'flex-start', marginTop: '18px' }} /> : dietPreferences && <DietaryIcons style={{ alignItems: 'flex-start' }} icons={dietPreferences} />}
            </>
          }
          <div className="ui-tags-mobile-anchor">
            <div
              className="ui-tags-wrapper mobile"
            >
              {uiTags && uiTags.map(tag => {
                if (tag.toLowerCase() == 'medium image') {
                  return (<React.Fragment key={tag}></React.Fragment>);
                } else if (tag.toLowerCase() == 'it\'s back!') {
                  return (<div
                    key={tag}
                    className={'ui-tag ui-tag-new'}
                  >
                    {tag}
                  </div>);
                } else {
                  return (<div
                    key={tag}
                    className={`ui-tag ui-tag-${tag.toLowerCase()}`}
                  >
                    {tag}
                  </div>);
                }
              })}
            </div>
          </div>

          {discounts?.length > 0 ? (
            <div className="price">
              <small>
                <strike>{config.CURRENCY_SYMBOL}{displayCost.toFixed(2)}</strike>
              </small>
              &nbsp;
              <span className="is-text-weight-semibold">
                {displayCostAfterDiscount !== 0 ? <>{config.CURRENCY_SYMBOL}{(displayCostAfterDiscount).toFixed(2)}</> : <>Free</>}
              </span>
            </div>) : (
            <span
              className="price is-text-weight-semibold"
            >
              {config.CURRENCY_SYMBOL}{displayCost.toFixed(2)}
            </span>
          )}
          {getCalories()}
          {extras?.base ? <span>{extras.base}</span> : null}

          {
            menuItem &&
            <div className='description-wrapper block is-flex'>
              <div className='level is-mobile is-align-top'>
                <div className="level-item is-narrow is-shrinkable">
                  <p
                    ref={textRef}
                    className={`description ${isExpanded ? 'expanded' : ''}`}
                  >
                    {description}
                  </p>
                </div>
                {
                  (showButton) &&
                  <div className="level-item">
                    <ExpandButton invert={!isExpanded} callback={expand} />
                  </div>
                }
              </div>
            </div>
          }
        </div>
        <div className={'button-container block block is-flex-direction-column is-justify-content-space-between pt-0 px-5'}>
          {menuItem ?
            bundleItem ? (
              <>
                <div className="button-wrapper block is-flex">
                  <div className="level is-align-items-stretch-tablet is-flex-grow-1 wrap-desktop-only is-stack-desktop">
                    <div className="level-item">
                      <button
                        className='btn btn-primary is-flex-grow-1-mobile is-fullwidth'
                        disabled={props.outOfStock} data-testid='navigate-bundle'
                        onClick={() => {
                          handleCustomiseClick();
                          navigateTo(`/menu/${restaurant?.id}/bundle/${name}`);
                        }}
                      >
                        {props.outOfStock ? 'Out of stock' : 'Customise'}
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {
                  checkIfPizza(subProducts) ?
                    <div className='is-flex-grow-1-mobile is-fullwidth'>
                      <DropDown>
                        <DropDownField
                          title='Choose your base'
                          name='base'
                          valueCallback={updateBaseSelection}
                          value={selectedBase}
                          options={subProducts[0].subProducts.map(option => ({ label:  option.name, value: option.id }))}
                          style={{}}
                          icon={chevronDown}
                          greyBg
                          titleExtraIdentifier={`${name} choose your base`}
                          idx={idx}
                          hasGap
                          disabled={outOfStock}
                        />
                      </DropDown>
                    </div>
                    :
                    null
                }
                <div className={`button-wrapper block level is-align-items-stretch-tablet is-stack-desktop is-flex-shrink-1 ${cardButtons.length === 1 ? 'single-button' : ''}`}>
                  {cardButtons.map((button, i) => (
                    <button key={`button-${i}`}
                      onClick={() => {
                        if (button.onClick) {
                          button.onClick();
                        }
                      }}
                      disabled={button.disabled}
                      className={`btn btn-${cardButtons.length - 1 == i ? 'primary' : 'secondary'} is-flex-grow-1-mobile is-fullwidth`}
                      style={{ paddingRight: 4, paddingLeft: 4 }} data-testid={button.testId}>
                      {button.disabled ? 'Out of stock' : button.text}
                    </button>
                  ))}
                </div>
              </>
            ) : (
              !props.outOfStock && (
                <div className="block is-flex is-justify-content-flex-end mb-0 mt-3-mobile">
                  <div className="level is-mobile">
                    {
                      customID &&
                      <div className="level-item more-space is-narrow">
                        <a className="link dm-sans" style={{ fontSize: 16, fontWeight: 400 }} onClick={() => navigateTo(`/menu/${restaurant.id}/${isBundle ? 'bundle' : 'customise'}/${isBundle ? name : id}`, { customID, quantity })}>Edit</a>
                      </div>
                    }
                    <div data-testid='decrease' className="level-item more-space is-narrow" onClick={() => removeItem(props.item)}>
                      <img src={minus} className="icon" />
                    </div>
                    <div className="level-item more-space is-narrow">
                      <div className="icon">
                        <p className='basket-card-quantity' data-testid='quantity'>{quantity}</p>
                      </div>
                    </div>
                    <div data-testid='increase' className="level-item is-narrow" onClick={() => addItem(props.item)}>
                      <img src={plus} className="icon" />
                    </div>
                  </div>
                </div>)
            )}
        </div>
      </div>
    </div >
  );
};

export default MenuItem;
